import {createI18n} from 'vue3-i18n';
import de from "./de";
import en from "./en";
import it from "./it";
import cz from "./cz";
import sk from "./sk";

// @ts-ignore
const i18n = createI18n({
    locale: localStorage.getItem('lang') || 'de',
    messages: {de, en, it, cz, sk}
});
export default i18n;