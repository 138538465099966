
const SupplierRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/supplier/list.vue"),
    },
    {
        path: 'list',
        name: 'list-supplier',
        component: () => import("@/components/admin/supplier/list.vue"),
    },
    {
        path: 'form',
        name: 'form-supplier',
        component: () => import("@/components/admin/supplier/form.vue"),
    }
];
export default SupplierRoutes