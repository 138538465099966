import {setup} from "vue-class-component";
import {useApolloClient, useMutation, useQuery, useSubscription} from "@vue/apollo-composable";
import {DocumentNode} from "graphql";

/**
 * Run a graphql query using apollo-composable and return a context object which can be used in the components DOM
 * @param query the graphql query object
 * @param filter
 */
export function gqlQuery(query: DocumentNode, filter: any = null) {
    return setup(() => useQuery(query, filter))
}

export function gqlSubscription(query: DocumentNode, filter: any = null) {
    return setup(() => useSubscription(query, filter))
}

export function gqlMutation(query: DocumentNode, values: any = null) {
    return setup(() => useMutation(query, {variables: values}))
}

export function gqlQueryRaw(query: DocumentNode, values: any = null) {
    return useApolloClient().client.query({
        query: query,
        variables: values
    })
}

export function gqlMutationRaw(query: DocumentNode, values: any = null) {
    return useApolloClient().client.mutate({
        mutation: query,
        variables: values
    })
}
