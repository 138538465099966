import { ApolloClient, InMemoryCache, HttpLink, DefaultOptions } from "@apollo/client/core"
import {DefaultApolloClient, provideApolloClient} from "@vue/apollo-composable";
import {Plugin} from "@vue/runtime-core/dist/runtime-core"

/**
 * NOTE: The "Authorization"-Header will be set by lbXhrAuthentication()-extension
 */

const httpLink =  new HttpLink({
    uri: `/v1/graphql`
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
} as DefaultOptions

const apolloClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
});

const plugin =  {
    install: (app, options) => {
        app.provide(DefaultApolloClient, apolloClient)
        provideApolloClient(apolloClient)
    }
} as Plugin

export default plugin
