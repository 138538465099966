
const UserRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/user/list.vue")
    },
    {
        path: 'list',
        name: 'list-user',
        component: () => import("@/components/admin/user/list.vue")
    },
    {
        path: 'form',
        name: 'form-user',
        component: () => import("@/components/admin/user/form.vue")
    }
];
export default UserRoutes