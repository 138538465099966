
const ProductGroupRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/product-group/list.vue"),
    },
    {
        path: 'list',
        name: 'list-product-group',
        component: () => import("@/components/admin/product-group/list.vue"),
    },
    {
        path: 'form',
        name: 'form-product-group',
        component: () => import("@/components/admin/product-group/form.vue"),
    }
];
export default ProductGroupRoutes