
const ProfitCenterRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/profit-center/list.vue")
    },
    {
        path: 'list',
        name: 'list-profit-center',
        component: () => import("@/components/admin/profit-center/list.vue")
    },
    {
        path: 'form',
        name: 'form-profit-center',
        component: () => import("@/components/admin/profit-center/form.vue")
    }
];
export default ProfitCenterRoutes