
const ProcurementRoutes = [
    {
        path: '',
        component: () => import("@/views/overview.vue")
    },
    {
        path: 'form',
        name: 'form-procurement',
        component: () => import("@/components/procurement/form.vue")
    }
];
export default ProcurementRoutes