
const CostCenterRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/cost-center/list.vue"),
    },
    {
        path: 'list',
        name: 'list-cost-center',
        component: () => import("@/components/admin/cost-center/list.vue"),
    },
    {
        path: 'form',
        name: 'form-cost-center',
        component: () => import("@/components/admin/cost-center/form.vue"),
    }
];
export default CostCenterRoutes