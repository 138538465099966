import TenantRoutes from "@/router/operator/tenant";

const OperatorRoutes = [
    {
        path: '',
        component: () => import("@/components/operator/tenant/list.vue"),
    },
    {
        path: 'tenant',
        name: 'tenant',
        component: () => import("@/components/operator/tenant/index.vue"),
        children: TenantRoutes
    },

];
export default OperatorRoutes