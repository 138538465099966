import {decode} from 'jsonwebtoken'
import {LSK_ACCESS_JWT, LSK_ACCESS_JWT_OTHER} from "@/utils";

export class CurrentUser {
    constructor(
        public jwtUser: JwtUser
    ) {
    }
}

export class JwtUser {
    constructor(
        public userId: string,
        public roles: Array<string>,
        public permissions?: Array<number>,
        public lang?: string
    ) {
    }
}


export class UserServiceImpl {

    getCurrentUser(): CurrentUser | null {
        const jwt = localStorage.getItem(LSK_ACCESS_JWT_OTHER) || localStorage.getItem(LSK_ACCESS_JWT)
        if (!jwt) return null

        return new CurrentUser(
            UserService.jwtUserFromToken(jwt)
        )
    }

    getLoggedUser(): CurrentUser | null {
        const jwt = localStorage.getItem(LSK_ACCESS_JWT)
        if (!jwt) return null

        return new CurrentUser(
            UserService.jwtUserFromToken(jwt)
        )
    }

    jwtUserFromToken(token: string): JwtUser {
        const jwtUser = decode(token)
        if (!jwtUser) {
            throw Error("invalid token")
        }

        //@ts-ignore
        const claims = jwtUser["https://hasura.io/jwt/claims"]
        const userId = claims["x-hasura-user-id"]
        const roles = claims["x-hasura-allowed-roles"]
        const lang = claims["x-hasura-user-lang"]
        const permissions = claims["x-hasura-user-permissions"]?.toString().split(",").map(Number) ?? []

        return new JwtUser(userId, roles, permissions, lang)
    }
}

const UserService = new UserServiceImpl()
export default UserService