const TaskGroupRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/task-group/list.vue"),
    },
    {
        path: 'list',
        name: 'list-task-group',
        component: () => import("@/components/admin/task-group/list.vue"),
    },
    {
        path: 'form',
        name: 'form-task-group',
        component: () => import("@/components/admin/task-group/form.vue"),
    }
];
export default TaskGroupRoutes