
const PostingKeyRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/posting-key/list.vue"),
    },
    {
        path: 'list',
        name: 'list-posting-key',
        component: () => import("@/components/admin/posting-key/list.vue"),
    },
    {
        path: 'form',
        name: 'form-posting-key',
        component: () => import("@/components/admin/posting-key/form.vue"),
    }
];
export default PostingKeyRoutes