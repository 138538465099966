import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import AdminRoutes from "@/router/admin";
import ProcurementRoutes from "@/router/procurement";
import OperatorRoutes from "@/router/operator";
import InvoiceRoutes from "@/router/invoice";


const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        name: 'index',
        component: () => import('../views/index.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue')
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('../views/logout.vue')
    },
    {
        path: '/login/:token',
        name: 'loginWithToken',
        component: () => import('../views/login.vue')
    },
    {
        path: '/procurement',
        name: 'procurement',
        component: () => import(/* webpackChunkName: "restructre" */ '../views/procurement.vue'),
        children: ProcurementRoutes
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: () => import(/* webpackChunkName: "restructre" */ '../views/invoice.vue'),
        children: InvoiceRoutes
    },
    {
        path: '/overview',
        name: 'overview',
        component: () => import(/* webpackChunkName: "restructre" */ '../views/overview.vue')
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import(/* webpackChunkName: "restructre" */ '../views/admin.vue'),
        children: AdminRoutes
    },
    {
        path: '/operator',
        name: 'operator',
        component: () => import(/* webpackChunkName: "restructre" */ '../views/operator.vue'),
        children: OperatorRoutes
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "restructre" */ '../views/profile.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "restructre" */ '../views/dashboard.vue')
    },
    {
        path: '/help',
        name: 'help',
        component: () => import(/* webpackChunkName: "restructre" */ '../views/help.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router
