
const DepartmentRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/department/list.vue"),
    },
    {
        path: 'list',
        name: 'list-department',
        component: () => import("@/components/admin/department/list.vue"),
    },
    {
        path: 'form',
        name: 'form-department',
        component: () => import("@/components/admin/department/form.vue"),
    }
];
export default DepartmentRoutes