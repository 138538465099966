import gql from "graphql-tag";
import {GqlTransaction} from "@/services/gql-transaction";
import {AuditLog} from "@/services/audit-log-service";
import { gqlMutationRaw} from "@/apollo-graphql/utils";

class AuditLogActionsImpl {

    constructor() {
    }

    // ====== GQL START ======

    private SAVE_LOG_GQL = gql`
        mutation saveNewAuditLog(
            $object: audit_log_insert_input!
        ) {
            insert_audit_log_one(object: $object) {
                id
            }
        }
    `

    // ====== GQL END ======

    save(auditLog: AuditLog, transaction?: GqlTransaction) {
        if (transaction) transaction.execute(this.SAVE_LOG_GQL, {object: auditLog})
        else gqlMutationRaw(this.SAVE_LOG_GQL, {object: auditLog})
    }

}

const AuditLogActions = new AuditLogActionsImpl()
export default AuditLogActions
