

import {Vue} from "@/extensions";
import UserService from "@/services/user-service";
import {gqlQuery, gqlQueryRaw} from "@/apollo-graphql/utils";
import gql from "graphql-tag";
import {Options} from "vue-class-component";
import {ElCollapseTransition} from "element-plus";
import {LSK_ACCESS_JWT, LSK_REFRESH_JWT} from "@/utils";

@Options({
  components: {
    ElCollapseTransition
  }
})
export default class NavBar extends Vue {
  user: any = {}
  currentUser: any = null
  loginMenuVisible = false

  mounted() {
    this.emitter.on("reload-navbar", this.updateNavbar)
    this.emitter.on("open-menu", this.open)
    this.emitter.on("close-menu", this.close)
    this.updateNavbar()
  }

  beforeUnmount() {
    this.emitter.off("reload-navbar", this.updateNavbar)
    this.emitter.off("open-menu", this.open)
    this.emitter.off("close-menu", this.close)
  }

  toggle() {
    this.loginMenuVisible = !this.loginMenuVisible
  }

  open() {
    this.loginMenuVisible = true
  }

  close() {
    this.loginMenuVisible = false
  }

  isEmailFromLifbase(email: string) {
    return email.indexOf('@ecm.online') >= 0
  }

  updateNavbar(args?: any) {
    if (!!args && args.logout) this.currentUser = null
    else {
      this.currentUser = this.getCurrentUser() ?? null
      this.getUserInfo((user: any) => {
        this.user = user;
        this.$forceUpdate()
      });
    }
    this.$forceUpdate()
  }

  gotoView(view: string) {
    this.loginMenuVisible = false
    this.$router.push({name: view})
  }

  closeTimeout: any

  startCloseDelay() {
    this.closeMenu()
  }

  cancelCloseDelay() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout)
      this.closeTimeout = null
    }
  }

  closeMenu() {
    this.closeTimeout = setTimeout(() => {
      this.loginMenuVisible = false;
    }, 1000);
  }

}

