import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "footer-logged-as"
}
const _hoisted_2 = {
  key: 0,
  class: "user-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["layout-header", {'no-event' : _ctx.blockPointerEvents}])
    }, [
      _createVNode(_component_nav_bar)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["layout-content", {'no-event' : _ctx.blockPointerEvents}]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.globalDismiss && _ctx.globalDismiss(...args)))
    }, [
      _createVNode(_component_el_config_provider, { locale: _ctx.locale }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }, 8, ["locale"])
    ], 2),
    (_ctx.isLoggedAsOther)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.currentUser)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("b", null, _toDisplayString(_ctx.loggedUser?.name), 1),
                _createTextVNode(" ist als "),
                _createElementVNode("b", null, _toDisplayString(_ctx.currentUser.name), 1),
                _createTextVNode(" eingeloggt. ")
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_el_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.logoutAsOtherUser();_ctx.reloadFooterLoggedAs();})
          }, {
            default: _withCtx(() => [
              _createTextVNode("Logout")
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}