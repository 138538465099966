const TenantRoutes = [
    {
        path: '',
        component: () => import("@/components/operator/tenant/list.vue"),
    },
    {
        path: 'list',
        name: 'list-tenant',
        component: () => import("@/components/operator/tenant/list.vue"),
    },
    {
        path: 'form',
        name: 'form-tenant',
        component: () => import("@/components/operator/tenant/form.vue"),
    },
    {
        path: 'procurement',
        name: 'form-tenant-procurement',
        component: () => import("@/components/operator/tenant/procurement.vue"),
    },
    {
        path: 'delete-procurement',
        name: 'delete-procurement',
        component: () => import("@/components/admin/configuration/procurement.vue"),
    },
    {
        path: 'invoice',
        name: 'easybill-invoice',
        component: () => import("@/components/invoice/index.vue"),
    },
    {
        path: 'delete-invoice',
        name: 'delete-invoice',
        component: () => import("@/components/admin/configuration/invoice.vue"),
    },
    {
        path: 'list-invoice',
        name: 'list-tenant-invoice',
        component: () => import("@/components/operator/tenant/invoice.vue"),
    }
];
export default TenantRoutes