
const ProductRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/product/list.vue"),
    },
    {
        path: 'list',
        name: 'list-product',
        component: () => import("@/components/admin/product/list.vue"),
    },
    {
        path: 'form',
        name: 'form-product',
        component: () => import("@/components/admin/product/form.vue"),
    }
];
export default ProductRoutes