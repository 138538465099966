
const InvoiceRoutes = [
    {
        path: '',
        component: () => import("@/views/overview.vue")
    },
    {
        path: 'form',
        name: 'form-invoice',
        component: () => import("@/components/invoice/upload.vue")
    },
    {
        path: 'upload',
        name: 'invoice-upload',
        component: () => import("@/components/invoice/upload.vue")
    }
];
export default InvoiceRoutes