
const PermissionRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/permission/list.vue"),
    },
    {
        path: 'list',
        name: 'list-permission',
        component: () => import("@/components/admin/permission/list.vue"),
    },
    {
        path: 'form',
        name: 'form-permission',
        component: () => import("@/components/admin/permission/form.vue"),
    }
];
export default PermissionRoutes