
const TaxItemGroupRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/tax-item-group/list.vue"),
    },
    {
        path: 'list',
        name: 'list-tax-item-group',
        component: () => import("@/components/admin/tax-item-group/list.vue"),
    },
    {
        path: 'form',
        name: 'form-tax-item-group',
        component: () => import("@/components/admin/tax-item-group/form.vue"),
    }
];
export default TaxItemGroupRoutes