import _, {toNumber} from "lodash";
import UserService from "@/services/user-service";
import {GqlTransaction} from "@/services/gql-transaction";
// @ts-ignore
import auditLogActions from "@/services/audit-log-actions";
import {isAccessingFromOperatorArea} from "@/extensions";
import dayjs from "dayjs";

export type AuditLog = {
    table: string,
    relation_table: string,
    type: AuditLogType,
    foreign_key: number,
    lb_user_id: number,
    lb_user?: any,
    affected_properties: Array<string>,
    data_before: any,
    data_after: any,
    created_at?: string,
    tenant_id?: number
}

export enum AuditLogType {
    insert = "insert",
    update = "update",
    delete = "delete",
    checkbox = "checkbox",
}

class AuditLogServiceImpl {

    saveAuditLogItem(table: string, type: AuditLogType, foreignKey: number, oldData: any, newData: any, relationTable?: string, transaction?: GqlTransaction, createdAt?: string) {
        const auditLog = this.getAuditLogItem(table, type, foreignKey, oldData, newData, relationTable, createdAt)
        if (this.hasChanges(auditLog)) auditLogActions.save(auditLog, transaction)
    }

    saveAuditLogList(table: string, foreignKey: number, oldList: Array<any>, newList: Array<any>, relationTable?: string, transaction?: GqlTransaction, createdAt?: string) {
        const keptItemIds = newList.filter((item: any) => !!item.id).map((item: any) => item.id)
        const oldItemIds = oldList.filter((item: any) => !!item.id).map((item: any) => item.id)

        const itemsToInsert = newList.filter((item: any) => (!item.id || !oldItemIds.includes(item.id)) && ([null, undefined].includes(item.active) || item.active !== false))
        itemsToInsert?.forEach((item: any) => this.saveAuditLogItem(table, AuditLogType.insert, foreignKey, null, item, relationTable, transaction, createdAt))

        const itemsToDelete = oldList.filter((item: any) => !keptItemIds.includes(item.id))
        itemsToDelete?.forEach((item: any) => this.saveAuditLogItem(table, AuditLogType.delete, foreignKey, item, null, relationTable, transaction, createdAt))

        const keptItemsBefore = oldList.filter((item: any) => keptItemIds.includes(item.id))
        const keptItemsAfter = newList.filter((item: any) => keptItemIds.includes(item.id))
        keptItemsBefore?.forEach((oldData: any) => {
            const newData = keptItemsAfter.find((newData: any) => oldData.id == newData.id)
            this.saveAuditLogItem(table, AuditLogType.update, foreignKey, oldData, newData, relationTable, transaction, createdAt)
        })
    }

    saveAuditLogListByKeys(table: string, foreignKey: number, oldList: Array<any>, newList: Array<any>, relationTable?: string, transaction?: GqlTransaction, createdAt?: string) {
        const foreignKeys = _.keys(newList[0] ?? oldList[0]).filter(key => key.endsWith("_id"))
        if (!foreignKeys?.length) return

        const keptItemIds = newList.map((item: any) => item[foreignKeys[0]] + "_" + item[foreignKeys[1]])
        const oldItemIds = oldList.map((item: any) => item[foreignKeys[0]] + "_" + item[foreignKeys[1]])

        const itemsToInsert = newList.filter((item: any) => !oldItemIds.includes(item[foreignKeys[0]] + "_" + item[foreignKeys[1]]))
        itemsToInsert?.forEach((item: any) => this.saveAuditLogItem(table, AuditLogType.insert, foreignKey, null, item, relationTable, transaction, createdAt))

        const itemsToDelete = oldList.filter((item: any) => !keptItemIds.includes(item[foreignKeys[0]] + "_" + item[foreignKeys[1]]))
        itemsToDelete?.forEach((item: any) => this.saveAuditLogItem(table, AuditLogType.delete, foreignKey, item, null, relationTable, transaction, createdAt))

    }

    saveAuditLogCheckboxList(table: string, foreignKey: number, oldList: Array<any>, newList: Array<any>, relationTable?: string, transaction?: GqlTransaction, createdAt?: string) {
        if (_.xor(oldList, newList)?.length > 0)
            this.saveAuditLogItem(table, AuditLogType.checkbox, foreignKey, oldList, newList, relationTable, transaction, createdAt)
    }

    getAuditLogItem(table: string, type: AuditLogType, foreign_key: number, oldData: any, newData: any, relation_table?: string, created_at?: string) {
        const lb_user_id = toNumber(UserService.getCurrentUser()?.jwtUser?.userId)
        let data_before: any
        let data_after: any
        let affected_properties: Array<string> = []

        if (type == AuditLogType.insert) {
            data_after = newData
        } else if (type == AuditLogType.delete || this.wasObjectInactivated(oldData, newData)) {
            data_before = oldData
            type = AuditLogType.delete
        } else if (type == AuditLogType.update) {
            affected_properties = this.getAffectedProperties(oldData, newData)
            let properties = (relation_table && !affected_properties.includes("name")) ?
                affected_properties.concat(["name"]) : affected_properties
            data_after = this.getDataOnlyWithProperties(newData, properties)
            data_before = this.getDataOnlyWithProperties(oldData, properties)
        } else {
            data_after = newData
            data_before = oldData
        }

        let auditLog = {
            table,
            type,
            foreign_key,
            lb_user_id,
            affected_properties,
            data_before,
            data_after,
            relation_table,
            created_at
        } as AuditLog

        if (isAccessingFromOperatorArea()) auditLog.tenant_id = foreign_key
        return auditLog
    }

    wasObjectInactivated(oldData: any, newData: any) {
        return oldData.active == true && !newData.active
    }

    hasChanges(auditLog: AuditLog) {
        return auditLog.type != AuditLogType.update || auditLog.affected_properties.length > 0
    }

    getAffectedProperties(oldData: any, newData: any) {
        const fieldsToIgnore = ["error", "title", "__typename"]
        const fieldsNewData = Object.keys(newData)?.filter((key: any) => key.indexOf("_id") == -1)
        const keys = _.uniq(Object.keys(oldData).concat(fieldsNewData))
        return keys.filter((key) => !fieldsToIgnore.includes(key))
            .filter((key) => this.areBothDifferent(oldData[key], newData[key]))
    }

    areBothDifferent(oldData: any, newData: any) {
        return (!this.areBothEmpty(oldData, newData) &&
            (
                (this.areNotObject(oldData, newData) && oldData !== newData)
                || ((typeof newData?.getDate == 'function' || !!newData?.$d) && !dayjs(oldData).isSame(newData))
            )
        )
    }

    areBothEmpty(oldData: any, newData: any) {
        return (oldData == null || false) && (newData == null || false)
    }

    areNotObject(oldData: any, newData: any) {
        return (typeof oldData != "object" || oldData == null) &&
            (typeof newData != "object" || newData == null)
    }

    getDataOnlyWithProperties(data: any, properties: Array<string>, includeNames: boolean = true) {
        let filteredData: any = {}
        _.assign(filteredData, data)

        if (!!data.name) Object.keys(data).filter(key => !properties.includes(key)).forEach(key => delete filteredData[key])

        if (includeNames) properties.filter((property: string) => property.endsWith("_id")).forEach((property: string) => {
            const objectName = property.replace("_id", "")
            const object = data[objectName]
            if (!!object) {
                if (!!object['name'])
                    filteredData[objectName + '_name'] = object['name']
                else if (!!object && !!object['number'])
                    filteredData[objectName + '_name'] = object['number']

                if (object['names'] || object['translations'])
                    filteredData[objectName + '_names'] = (object['names'] || object['translations'])
            }
        })

        Object.keys(filteredData).forEach(key => {
            if (typeof filteredData[key]?.getDate == 'function') filteredData[key] = dayjs(filteredData[key]).format("YYYY-MM-DD")
        })

        return filteredData
    }

}


const AuditLogService = new AuditLogServiceImpl()
export default AuditLogService
