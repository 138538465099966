
const ClientRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/client/list.vue"),
    },
    {
        path: 'list',
        name: 'list-client',
        component: () => import("@/components/admin/client/list.vue"),
    },
    {
        path: 'form',
        name: 'form-client',
        component: () => import("@/components/admin/client/form.vue"),
    }
];
export default ClientRoutes