import * as Sentry from "@sentry/vue";
import router from "@/router";
import {Plugin} from "@vue/runtime-core";

const plugin = {
    install: (app, options) => {
        Sentry.init({
            app,
            dsn: "https://2a75c97c13d241d299f4ad679b271177@o1243447.ingest.sentry.io/6398769",
            integrations: [
                new Sentry.BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                    tracePropagationTargets: [
                        "localhost",
                        "app-dev.liftbase.de",
                        "app-dev-3.liftbase.de",
                        "app-demo.liftbase.de",
                        "app-test.liftbase.de",
                        "app.liftbase.de",
                        /^\//
                    ],
                }),
                new Sentry.Replay()
            ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
            // This sets the sample rate to be 10%. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: 0.1,
            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate: 1.0,
        });
    }
} as Plugin

export default plugin
