
const TaxGroupRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/tax-group/list.vue"),
    },
    {
        path: 'list',
        name: 'list-tax-group',
        component: () => import("@/components/admin/tax-group/list.vue"),
    },
    {
        path: 'form',
        name: 'form-tax-group',
        component: () => import("@/components/admin/tax-group/form.vue"),
    }
];
export default TaxGroupRoutes